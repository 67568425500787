.rating-item{
    margin-bottom: size(24);
    h6{
        margin-bottom: size(14);
    }
    .bs-rating-star{
        font-size: size(20);
        color: $gray;
        margin-right: size(6);
        &.active{
            color: $primary;
        }
    }
}
.rating-box{
    h6{
        margin-bottom: size(14);
    }
}