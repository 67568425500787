.modal-backdrop {
  z-index: 1100;
}

.modal {
  z-index: 1111;
  // padding-left: $nav-sidebar-width;
  // @include media(down, $breakpoint-xxl){
  //     padding-left: 300px;
  // }
  // @include media(down, $breakpoint-xl){
  //     padding-left: 240px;
  // }
}

.modal-dialog {
  max-width: 375px;

  @include media(down, $breakpoint-md) {
    max-width: 94%;
  }

  &.modal-md {
    max-width: 430px;
  }

  &.modal-lg {
    max-width: 480px;
  }

  &.modal-xlg {
    max-width: 685px;
  }
}

.modal-content {
  border: none;
  background-color: $white;
  border-radius: $border-radius-24;
}

.quiz-modal {
  margin: 1em;
}

.modal-header {
  border-bottom: 1px solid $border-color-light;
  padding: size(30) size(30) size(20) size(30);

  @include media(down, $breakpoint-xl) {
    padding: size(20);
  }

  h5 {
    margin-bottom: 0;
  }
}

.modal-body {
  padding: size(30);

  @include media(down, $breakpoint-xl) {
    padding: size(20);
  }
}

.modal-footer {
  border-top: none;
  padding: 0 size(20) size(20) size(20);

  @include media(down, $breakpoint-xl) {
    padding: 0 size(16) size(16) size(16);
  }

  .btn {
    margin-left: size(16);
  }
}

.modal-title {
  font-size: size(24);
  font-weight: $font-regular;

  @include media(down, $breakpoint-xl) {
    font-size: size(22);
  }
}

.modal-close {
  position: fixed;
  right: size(24);
  top: size(24);
  font-size: size(48);
  line-height: size(48);
  color: $white;
  outline: none !important;
  background: transparent;
  border: none;
  width: 48px;
  height: 48px;
  padding: 0;
  @include Transition(all 0.3s ease-in-out);

  &:hover {
    color: $primary;
  }
}

.quiz-modal-close {
  outline: none !important;
  background: transparent;
  border: none;
  padding: 0;
  font-size: 30px;
  @include Transition(all 0.3s ease-in-out);

  &:hover {
    color: $primary;
  }
}

.modal-header .close:focus {
  outline: none;
}

.modal-action {
  margin-top: size(30);
  text-align: center;

  &.flex-action {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn {
      margin: 0 6px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

body.modal-open .admin-content-wrapper,
body.modal-open .header-section {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}

.modal.fade,
.modal-backdrop.fade {
  transition: opacity 0s linear;
}

.modal.fade .modal-dialog {
  transition: transform 0s ease-in-out;
}

.fullscreen-quiz-modal {
  &.modal-dialog {
    max-width: 100%;
  }

  .modal-body {
    @include media(down, $breakpoint-xxxl + 1px) {
      //max-height: 440px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    // @include media(down, $breakpoint-xxl) {
    //   max-height: 420px;
    // }

    @include media(down, $breakpoint-xl) {
      max-height: 520px;
    }

    @include media(down, $breakpoint-lg) {
      max-height: 650px;
    }
  }
}