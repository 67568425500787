.sidebar {
  width: $nav-sidebar-width;
  height: 100%;
  background: $white;
  max-width: $nav-sidebar-width;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  transform: translateX(0);
  @include media(down, $breakpoint-xxl) {
    width: 300px;
  }
  @include media(down, $breakpoint-xl) {
    width: 240px;
  }
  .sidebar-inner-wrapper {
    position: relative;
    height: 100%;
    .sidebar-menu {
      padding: 30px;
      height: calc(100% - 184px);
      overflow: auto;
      position: relative;
      @include media(down, $breakpoint-xl) {
        padding: 20px;
      }
      .sidebar-menu-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        .menu-list-item {
          display: block;
          &:first-child {
            a {
              padding-top: 0;
            }
          }
          &:last-child {
            a {
              padding-bottom: 0;
            }
          }
          a {
            font-weight: $font-bold;
            font-size: size(16);
            letter-spacing: size(-0.25);
            line-height: size(22);
            color: $dark;
            padding: size(20) size(0);
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            align-content: center;
            cursor: pointer;
            .menu-title {
              margin-left: size(20);
            }
            em {
              color: $primary;
              font-size: 30px;
              @include Transition(all 0.3s ease-in-out);
            }
            &:hover,
            &.active {
              em {
                color: $secondary;
              }
            }
          }
        }
      }
    }
  }
  .sidebar-contact-icon {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(down, $breakpoint-xl) {
      padding: 20px;
    }
    a {
      margin: size(0) size(10);
      width: 100%;
      border-radius: $border-radius-12;
      box-shadow: 0 8px 16px 0 rgba(96, 97, 112, 0.3);
      display: flex;
      justify-content: center;
      height: 48px;
      align-items: center;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      em {
        font-size: size(24);
        color: $white;
      }
    }
  }
  .logo-box {
    background-repeat: no-repeat;
    background-size: cover;
    height: 76px;
    padding: size(16) size(26);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media(down, $breakpoint-xl) {
      padding: size(16) size(20);
    }
    .logo {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .down-arrow {
      em {
        font-size: size(24);
        color: $white;
      }
    }
    .short-text-wrap {
      .short-text {
        width: 45px;
        height: 45px;
        display: inline-block;
        background-color: $white;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: $border-radius-12;
        font-size: 27px;
        overflow: hidden;
        @include media(down, $breakpoint-xl) {
          width: 35px;
          height: 35px;
          font-size: size(18);
        }
      }
      h2 {
        font-size: size(22);
        @include media(down, $breakpoint-xxl) {
          font-size: size(18);
        }
        @include media(down, $breakpoint-xl) {
          font-size: size(14);
        }
      }
    }
  }
}
