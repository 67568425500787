.no-result-found{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 136px);
    h1{
        margin-bottom: size(44);
    }
}
.search-result-section{
    .result-section{
        border-bottom: 1px solid $border-color-light;
        margin-bottom: size(20);
        &:last-child{
            border-bottom: none;
            margin-bottom: size(0);
        }
    }
    .result-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: size(10);
        .title-tag{
            font-size: size(13);
            font-weight: $font-bold;
            line-height: size(18);
            letter-spacing: size(-0.03);
            background-color: $primary;
            color: $white;
            padding: size(4) size(10);
            border-radius: $rounded-pill;
            &.warning{
                background-color: $warning;
            }
            &.secondary{
                background-color: $secondary;
            }
            &.success{
                background-color: $success;
            }
            &.primary{
                background-color: $primary;
            }
        }
        .bx{
            font-size: size(24);
            color: $primary;
        }
    }
    .result-row{
        display: flex;
        flex-wrap: wrap;
        margin-right: -7px;
        margin-left: -7px;
    }
    .result-col{
        flex: 0 0 12.5%;
        max-width: 12.5%;
        width: 100%;
        padding-right: 7px;
        padding-left: 7px;
        margin-bottom: size(20);
        @include media(down, $breakpoint-xl){
            flex: 0 0 25%;
            max-width: 25%;
        }
    }
    .result-item{
        cursor: pointer;
        .img-box{
            width: 90px;
            height: 90px;
            padding: size(10);
            border: 1px solid $border-color-light;
            border-radius: $border-radius;
            background-color: $white;
            img{
                width: 68px;
                height: 68px;
                object-fit: cover;
                border-radius: $border-radius;
            }
        }
        p{
            font-size: size(15);
            line-height: size(22);
            color: $dark;
            font-weight: $font-regular;
            letter-spacing: size(-0.12);
            margin-bottom: 0;
            margin-top: size(5);
        }
    }
}




.search-filter-modal{
    .filter-option{
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: size(0) size(-6);
            li{
                padding: size(6) size(6) size(6) size(6);
                a{
                    font-size: size(15);
                    font-weight: $font-bold;
                    line-height: size(20);
                    letter-spacing: size(-0.03);
                    border: 2px solid $primary;
                    color: $primary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: size(6) size(12);
                    border-radius: $rounded-pill;
                    &.warning{
                        color: $warning;
                        border-color: $warning;
                    }
                    &.secondary{
                        color: $secondary;
                        border-color: $secondary;
                    }
                    &.success{
                        color: $success;
                        border-color: $success;
                    }
                    &.primary{
                        color: $primary;
                        border-color: $primary;
                    }
                    .bx{
                        font-size: size(20);
                        color: $white;
                        display: none;
                        margin-right: size(6);
                    }
                    &.active{
                        color: $white;
                        &.warning{
                            color: $white;
                            background-color: $warning;
                        }
                        &.secondary{
                            color: $white;
                            background-color: $secondary;
                        }
                        &.success{
                            color: $white;
                            background-color: $success;
                        }
                        &.primary{
                            color: $white;
                            background-color: $primary;
                        }
                        .bx{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}