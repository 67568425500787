.header-section {
  background: $white;
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 76px;
  padding-left: 335px;
  z-index: 999;

  @include media(down, $breakpoint-xxl) {
    padding-left: 300px;
  }

  @include media(down, $breakpoint-xl) {
    padding-left: 240px;
  }

  .header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: size(15) size(30) size(15) size(30);
    position: relative;

    .page-title {
      display: flex;
      align-items: center;

      .welcome-text {
        font-weight: $font-regular;
        margin-bottom: 0;

        span {
          color: $primary;
          font-weight: $font-bold;
        }
      }

      h2 {
        margin-bottom: 0;
      }

      .icon-bx {
        font-size: size(24);
        color: $dark;
        margin-right: size(15);
        cursor: pointer;
      }
    }
  }

  .header-actions {
    display: flex;
    align-items: center;

    .nav-item {
      margin-left: size(16);

      &:first-child {
        margin-left: 0;
      }
    }

    .user-dropdown {
      .dropdown-toggle {
        position: relative;

        img {
          width: 24px;
          height: 24px;
          border-radius: $border-radius;
        }

        .online-status {
          width: 14px;
          height: 14px;
          background: $primary;
          border: 2px solid $white;
          position: absolute;
          right: -4px;
          top: -4px;
          border-radius: $rounded-pill;
        }

        &::after {
          display: none;
        }
      }

      .dropdown-menu {
        position: absolute;
        right: -20px !important;
      }
    }

    .nav-search {
      .search-ic {
        font-size: size(24);
        color: $gray;

        &:hover {
          color: $primary;
        }

        .bx-search {
          margin-top: 5px;
        }
      }

      .search-input {
        background: $white;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 999;
        height: 100%;
        padding: 0 30px;
        display: none;

        &.search-open {
          display: block;
        }
      }

      .search-box {
        display: flex;
        align-items: center;
        height: 100%;

        .search-input-icon {
          .bx {
            font-size: size(24);
            color: $gray;
          }
        }

        .search-input-close {
          .bx {
            font-size: size(24);
            color: $dark;
            cursor: pointer;

            &:hover {
              color: $primary;
            }
          }
        }

        .input {
          font-size: size(16);
          line-height: size(24);
          border: none;
          outline: none;
          background: $white;
          padding: size(15);
          height: 100%;
          width: 100%;
        }
      }
    }

    .nav-icon {
      .icon-box {
        font-size: size(24);
        color: $primary;

        &:hover {
          color: $secondary;
        }
      }
    }

    .nav-icon-border {
      .icon-box {
        font-size: size(16);
        color: $primary;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: $primary;
          border-color: $primary;
          color: $white;
        }
      }
    }

    .nav-icon-button {
      .button-box {
        font-size: size(13);
        line-height: size(18);
        letter-spacing: size(-0.03);
        color: $dark;
        font-weight: $font-regular;
        padding: size(6) size(8);
        border: 1px solid $border-color;
        border-radius: $border-radius;
        height: 32px;
        display: flex;
        align-items: center;

        .bx {
          font-size: size(16);
          color: $gray;
          margin-right: size(8);
          @include Transition(all 0.3s ease-in-out);
        }

        &:hover {
          background: $primary;
          border-color: $primary;
          color: $white;

          .bx {
            color: $white;
          }
        }
      }
    }

    .nav-text-button {
      .text-link-btn {
        font-size: size(13);
        line-height: size(18);
        letter-spacing: size(-0.03);
        font-weight: $font-bold;
      }
    }

    .nav-item {
      .bx-flip-horizontal {
        font-size: size(24);
        color: $gray;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .nav-link-label {
    position: relative;
    padding: 0 1rem;

    .badge.badge-up {
      position: absolute;
      top: -4px;
      right: -4px;
      color: $white;
      padding: 0.25rem 0.4rem 0.18rem 0.35rem;
      width: 16px;
      height: 16px;
      line-height: size(10);
      font-weight: $font-bold;
      font-size: size(10);
      display: flex;
      align-items: center;
      justify-content: center;

      &.big-digit {
        width: 25px;
        right: -14px;
        top: -6px;
      }
    }
  }

  .bx-bell {
    font-size: size(24);
    color: $gray;
    vertical-align: middle;
    cursor: pointer;
    -webkit-text-stroke: 0.2px $white;
  }

}