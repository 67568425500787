.mylist-listing{
    .mylist-box{
        display: flex;
        align-items: flex-start;
        border-bottom:1px solid $border-color-light;
        padding: size(15) size(0);
        .mylist-item{
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba($primary, 10%);
            flex: 0 0 auto;
            border-radius: $rounded-pill;
            span{
                font-size: size(15);
                font-weight: $font-bold;
                color: $primary;
            }
        }
        .mylist-content{
            padding-left: size(12);
            width: calc(100% - 44px);
            padding-top: size(11);
            .title-box{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                margin-bottom: size(10);
                h6{
                    margin-bottom: 0;
                    a{
                        color: $dark;
                        &:hover{
                            color: $primary;
                        }
                    }
                }
                .mylist-dropdown{
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    .dropdown-toggle{
                        font-size: size(24);
                        color: $gray;
                        &::after{
                            display: none;
                        }
                    }
                    .dropdown-menu{
                        position: absolute;
                        right: -4px !important;
                        &::before{
                            right: 2px;
                        }
                    }
                }
            }
            p{
                font-size: size(12);
                line-height: size(18);
                font-weight: $font-regular;
                color: $gray;
                margin-bottom: size(10);
                letter-spacing: 0;
            }
            .mylist-info{
                display: flex;
                align-items: center;
                .item-tag{
                    display: flex;
                    align-items: center;
                    font-size: size(15);
                    font-weight: $font-bold;
                    color: $dark;
                    margin-right: size(30);
                    .bx{
                        font-size: size(24);
                        color: $secondary;
                        margin-right: size(10);
                    }
                }
            }
        }
    }
}

.addlist-modal{
    .modal-body{
        padding: size(0) size(16) size(30) size(16);
    }
}
.mylist-item-list{
    display: flex;
    align-items: center;
    border-bottom:1px solid $border-color-light;
    padding: size(15) size(10);
    cursor: pointer;
    .mylist-item{
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($primary, 10%);
        flex: 0 0 auto;
        border-radius: $rounded-pill;
        span{
            font-size: size(15);
            font-weight: $font-bold;
            color: $primary;
        }
    }
    .title-box{
        padding-left: size(12);
        width: calc(100% - 44px);
        h6{
            margin-bottom: 0;
        }
    }
}

.tab-wrapper{
    position: relative;
    .sort-ic{
        position: absolute;
        top: 10px;
        right: 0;
        a{
            font-size: size(24);
            color: $primary;
            &:hover{
                color: $secondary;
            }
        }
    }
}
.comman-tabs {
    .nav-tabs {
        background-color: $white;
        box-shadow: $box-shadow-lg;
        border-radius: $border-radius;
        overflow: hidden;
        border:none;
        width: calc(100% - 44px);
        .nav-item{
            width: 33.33%;
            text-align: center;
            margin-bottom: 0;
        }
        .nav-link {
            font-size:size(15);
            font-weight: $font-regular;
            line-height: size(22);
            color: $dark;
            border: none;
            border-bottom: 2px solid transparent;
            padding: size(12);
            background-color: transparent;
            &.active {
                color: $primary;
                border-bottom: 2px solid $primary;
            }
        }
    }
    .tab-content {
        padding-top: size(30);
    }
}

.product-info-modal{
    border: 1px solid $border-color-light;
    border-radius: $border-radius;
    padding: size(12);
    display: flex;
    .img-box{
        flex: 0 0 auto;
        img{
            width: 82px;
            height: 82px;
            object-fit: cover;
            border-radius: $border-radius;
        }
    }
    .product-detail{
        width: calc(100% - 82px);
        padding-left: size(12);
        h5{
            font-size: size(15);
            line-height: size(18);
            color: $dark;
            font-weight: $font-bold;
            letter-spacing: size(-0.12);
            margin-bottom: size(4);
        }
        h6{
            font-size: size(12);
            line-height: size(18);
            color: $primary;
            font-weight: $font-bold;
            margin-bottom: size(4);
        }
        span{
            font-size: size(10);
            line-height: size(14);
            color: $gray;
            font-weight: $font-regular;
            letter-spacing: size(0.1);
            margin-bottom: 0;
            display: block;
        }
        p{
            font-size: size(12);
            line-height: size(18);
            color: $gray;
            font-weight: $font-regular;
            margin-bottom: 0;
        }
    }
}

.product-item-modal{
    .icon-title{
        display: flex;
        align-items: center;
        border-bottom: 1px solid $border-color-light;
        cursor: pointer;
        padding: size(15) size(10);
        &:last-child{
            border-bottom: none;
        }
        .icon-box{
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 auto;
            border-radius: $rounded-pill;
            .bx{
                font-size: size(20);
            }
        }
    }
    .title-box{
        padding-left: size(12);
        width: calc(100% - 44px);
        h6{
            margin-bottom: 0;
        }
    }
    .primary-bg{
        .icon-box{
            background: rgba($primary, 10%);
        }
        .bx{
            color: $primary;
        }
        .img-block{
            .bx{
                color: rgba($primary, 20%);
            } 
        }
    }
    .secondary-bg{
        .icon-box{
            background: rgba($secondary, 10%);
        }
        .bx{
            color: $secondary;
        }
        .img-block{
            .bx{
                color: rgba($secondary, 20%);
            } 
        }
    }
    .info-bg{
        .icon-box{
            background: rgba($info, 10%);
        }
        .bx{
            color: $info;
        }
        .img-block{
            .bx{
                color: rgba($info, 20%);
            } 
        }
    }
    .success-bg{
        .icon-box{
            background: rgba($success, 10%);
        }
        .bx{
            color: $success;
        }
        .img-block{
            .bx{
                color: rgba($success, 20%);
            } 
        }
    }
    .warning-bg{
        .icon-box{
            background: rgba($warning, 10%);
        }
        .bx{
            color: $warning;
        }
        .img-block{
            .bx{
                color: rgba($warning, 20%);
            } 
        }
    }
    .purple-bg{
        .icon-box{
            background: rgba($purple, 10%);
        }
        .bx{
            color: $purple;
        }
        .img-block{
            .bx{
                color: rgba($purple, 20%);
            } 
        }
    }
    .pink-bg{
        .icon-box{
            background: rgba($pink, 10%);
        }
        .bx{
            color: $pink;
        }
        .img-block{
            .bx{
                color: rgba($pink, 20%);
            } 
        }
    }
    .chocolate-bg{
        .icon-box{
            background: rgba($chocolate, 10%);
        }
        .bx{
            color: $chocolate;
        }
        .img-block{
            .bx{
                color: rgba($chocolate, 20%);
            } 
        }
    }
}