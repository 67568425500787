.course-item-box {
    background: $white;
    border: 1px solid $border-color-light ;
    padding: size(10);
    margin-bottom: size(16);
    border-radius: $border-radius;
    display: flex;
    position: relative;
    cursor: pointer;
    @include Transition(all 0.3s ease-in-out);

    &:hover {
        box-shadow: $box-shadow-lg;
    }

    &.visited {
        border-color: $primary;
        background: $primary;

        .course-detail {
            h6 {
                a {
                    color: $white;

                    &:hover {
                        color: $dark;
                    }
                }
            }

            .kit-info {
                color: $dark;
            }
        }
    }

    .course-img {
        flex: 0 0 auto;
        width: 96px;
        position: relative;

        img {
            width: 96px;
            height: 96px;
            object-fit: cover;
            border-radius: $border-radius;
        }
    }

    .course-detail {
        width: calc(100% - 180px);
        padding-left: size(10);
        display: flex;
        flex-wrap: wrap;
        position: relative;

        h6 {
            font-size: size(15);
            line-height: size(20);
            letter-spacing: size(-0.12);
            margin-bottom: size(8);
            align-self: flex-start;

            a {
                color: $dark;

                &:hover {
                    color: $primary;
                }
            }
        }

        .course-info {
            width: 100%;
            align-self: flex-end;

            .progress-bar {
                border-radius: $border-radius;
            }
        }

        .information-box {
            display: flex;
            align-items: center;
            margin-top: size(12);

            a {
                font-size: size(12);
                line-height: size(18);
                color: $dark;
                display: flex;
                align-items: center;

                .bx {
                    font-size: size(24);
                    color: $primary;
                    margin-right: 4px;
                }
            }

            .bx-stop {
                font-size: size(7);
                color: $dark;
                margin: 0 9px;
            }
        }
    }

    .course-dropdown {
        position: absolute;
        right: 2px;
        top: 6px;

        .dropdown-toggle {
            font-size: size(24);
            color: $gray;

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            position: absolute;
            right: -4px !important;

            &::before {
                right: 2px;
            }
        }
    }

    .two-icon-box {
        position: relative;
        right: inherit;
        top: inherit;

        .dropdown-toggle {
            position: absolute;
            right: -4px;
            top: 0px;
        }

        .heart-ic {
            position: absolute;
            right: 0px;
            bottom: 0px;

            .bx {
                font-size: size(16);
                color: $primary;
            }
        }
    }

    .info-over {
        position: absolute;
        right: size(8);
        top: size(30);
        color: rgba($gray, 60%);
        font-size: size(24);
        cursor: pointer;
    }

    .kit-info {
        color: $primary;
        font-size: size(12);
        font-weight: $font-bold;
    }

    &.course-small {
        // cursor: pointer;
        align-items: flex-start;

        .course-detail {
            h6 {
                margin-bottom: size(14);
            }
        }

        .course-img {
            width: size(72);

            img {
                width: size(72);
                height: size(72);
            }

            .bx {
                position: absolute;
                top: 0;
                left: 0;
                right: 00;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-size: size(24);
            }
        }
    }

    &.kit-item-box {
        .course-img {
            width: size(66);

            img {
                width: size(66);
                height: size(66);
            }
        }

        .course-detail {
            width: calc(100% - 66px);
        }
    }

    &.disable-course {
        background-color: rgba($gray, 30%);
    }
}