.maintenance-wrap {
    .content-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .content-info {
        max-width: 75%;
        margin: 15px auto;

        @include media(down, $breakpoint-sm) {
            max-width: 100%;
        }
    }

    .content-para {
        color: #999999;
        font-size: 16px;
    }

    .maintenance-img {
        max-width: 35%;
        margin: 0 auto;

        @include media(down, $breakpoint-sm) {
            max-width: 75%;
        }
    }
}