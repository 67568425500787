.courses-box {
  background-color: $white;
  border: solid 1px $border-color-light;
  border-radius: $border-radius;
  overflow: hidden;
  margin-bottom: size(30);
  cursor: pointer;
  @include Transition(all 0.3s ease-in-out);

  &:hover {
    box-shadow: $box-shadow-lg;
  }

  .courses-image {
    position: relative;

    img {
      width: 100%;
      height: 167px;
      object-fit: cover;
    }
  }

  .progress {
    border-radius: 0;
    height: size(8);
    background-color: $dark;
    position: absolute;
    bottom: 0;
  }

  .progress-bar {
    background-color: $secondary;
    border-radius: 0;
  }

  .courses-disc {
    padding: size(14);
    min-height: 144px;
  }

  h5 {
    margin-bottom: size(16);
    padding-bottom: size(8);
    position: relative;

    &::after {
      content: "";
      width: size(52);
      height: size(2);
      background-color: $dark;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    &.no-after {
      &::after {
        display: none;
      }
    }
  }

  h6 {
    margin-bottom: size(2);
  }

  p {
    font-size: size(12);
    color: $gray;
    line-height: 1.5;
    margin-bottom: size(10);
    letter-spacing: 0;
  }

  .left-modules {
    font-size: size(13);
    font-weight: $font-bold;
  }
}

.view-more-blur {
  .courses-box {
    filter: blur(4px);
    box-shadow: $box-shadow-lg;
  }

  .btn-view-more {
    font-size: 16px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 4px;
    padding: 6px 15px;

    @include media(down, $breakpoint-lg) {
      width: max-content;
    }
  }
}

.courses-owl {
  .owl-stage {
    padding-left: 0 !important;
  }
}

.right-zero {
  .owl-stage-outer {
    margin-right: -30px;
  }
}

.product-box {
  @include flex-only;
  background-color: $white;
  border: solid 1px $border-color-light;
  border-radius: $border-radius;
  overflow: hidden;
  padding: size(10);
  margin-bottom: size(30);
  cursor: pointer;
  @include Transition(all 0.3s ease-in-out);

  &:hover {
    box-shadow: $box-shadow-lg;
  }

  .pb-image {
    width: 68px;

    img {
      width: 100%;
      height: 68px;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  .pb-content {
    width: calc(100% - 78px);
  }

  .pb-top {
    min-height: size(48);
  }

  .pb-title {
    font-size: size(15);
    font-weight: $font-bold;

    a {
      color: $dark;
    }
  }

  .pb-id {
    color: $primary;
    font-size: size(12);
    font-weight: $font-bold;
  }
}