.vendor-list{
    .vendor-box{
        background: $white;
        border: 1px solid $border-color-light;
        border-radius: $border-radius;
        padding: size(30);
        cursor: pointer;
        height: calc(100% - 30px);
        margin-bottom: size(30);
        @include Transition(all 0.3s ease-in-out);
        &:hover{
            box-shadow: $box-shadow-lg;
        }
        .img-box{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 108px;
            margin: 0 auto;
            img{
                width: 108px;
                height: 108px;
                object-fit: cover;
                border-radius: $border-radius;
            }
        }
        h6{
            margin-bottom: 0;
            margin-top: size(18);
        }
        .action-box{
            display: flex;
            align-items: center;
            margin-top: size(20);
            .file-icon{
                font-size: size(24);
                color: $gray;
                margin-right: size(10);
                &:last-child{
                    margin-right: 0;
                }
                &.primary-ic{
                    color: $primary;
                }
                &.secondary-ic{
                    color: $secondary;
                }
            }
        }
    }
}

.product-view-section{
    .product-slider{
        max-width: 557px;
        margin: 0 auto;
    }
    .product-content{
        padding-top: size(30);
        h6{
            color: $primary;
            font-size: size(15);
            font-weight: $font-bold;
            line-height: size(22);
            letter-spacing: size(-0.12);
            margin-bottom: size(6);
        }
    }
    .product-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            color: $primary;
            font-size: size(12);
            font-weight: $font-bold;
            line-height: size(18);
        }
    }
    .product-points{
        margin-bottom: size(12);
        li{
            font-size: size(15);
            line-height: size(22);
            color: $dark;
            letter-spacing: size(-0.12);
            font-weight: $font-regular;
            display: flex;
            align-items: flex-start;
            margin-bottom: size(6);
            .bx{
                font-size: size(12);
                color: $gray;
                margin-right: size(6);
                margin-top: size(6);
            }
        }
    }
    .button-box{
        text-align: center;
        padding-top: size(20);
        .btn{
            margin: 0 6px;
        }
    }
    .slick-dots{
        li{
            height: 8px;
            width: 8px;
            margin: 0 4px;
            button{
                height: 8px;
                width: 8px;
                padding: 0;
                &::before{
                    height: 8px;
                    width: 8px;
                    font-size: 8px;
                    line-height: 8px;
                    color: $gray;
                    opacity: 1;
                }
            }
            &.slick-active{
                button:before{
                    color: $secondary;
                    opacity: 1;
                }
            }
        }
    }
}