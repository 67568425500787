.course-progress-status {
  background: rgba($dark, 05%);
  padding: size(14);
  margin: size(-30) size(-30) size(30) size(-30);

  .progress {
    background-color: rgba($gray, 40%);
  }

  .flex-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.course-view-list {
  .card {
    padding: size(0);
    border: 1px solid $border-color-light;
    background: $white;
    border-radius: $border-radius;
    @include Transition(all 0.3s ease-in-out);

    &:hover {
      box-shadow: $box-shadow-lg;
    }

    .card-header {
      border: none;
      border-radius: 0;
      background: transparent;
      padding: 0;
    }

    .module-view {
      cursor: pointer;
    }

    .module-image {
      width: 138px;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }

  .panel {
    margin-bottom: size(16);
  }

  .list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: size(20);

    h5 {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    .bx-lock {
      font-size: size(24);
      color: $primary;
      margin-right: size(16);
    }

    .bx-info-circle {
      font-size: size(24);
      color: $gray;
    }
  }

  .panel-collapse {
    padding: size(0) size(20) size(20) size(20);
  }

  .panel-body {
    display: flex;

    @include media(down, $breakpoint-lg) {
      display: block;
    }
  }

  .course-module-list {
    width: 100%;
    margin-left: size(60);
    cursor: pointer;

    @include media(down, $breakpoint-lg) {
      margin-left: 0;
      margin-top: 20px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        align-items: center;
        margin-bottom: size(12);
        width: 50%;

        span {
          color: $dark;
          font-size: size(12);
          font-weight: $font-regular;
          line-height: size(18);
          margin-left: size(8);
          // &:hover {
          //   color: $primary;
          // }
        }
      }
    }
  }

  .course-completed {
    .card {
      background: $primary;
      border-color: $primary;
    }

    .list-title {
      h5 {
        color: $white;
      }

      .bx-info-circle {
        color: $white;
      }

      .bx-lock {
        color: $white;
      }
    }

    .course-module-list {
      cursor: pointer;

      ul {
        li {
          a {
            color: $white;

            &:hover {
              color: $dark;
            }
          }
        }
      }
    }

    // circle-progress{
    //     circle, path{
    //         stroke: $white;
    //     }
    //     tspan{
    //         fill: $white;
    //     }
    // }
  }

  .course-lock {
    pointer-events: none;
  }

  .module-expand {
    cursor: pointer;
  }
}