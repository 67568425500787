@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?k8l6g2");
  src: url("../../assets/fonts/icomoon.eot?k8l6g2#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/icomoon.ttf?k8l6g2") format("truetype"),
    url("../../assets/fonts/icomoon.woff?k8l6g2") format("woff"),
    url("../../assets/fonts/icomoon.svg?k8l6g2#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-home:before {
  content: "\e908";
}
.icon-website:before {
  content: "\e907";
}
.icon-favourites:before {
  content: "\e900";
}
.icon-featured-products:before {
  content: "\e901";
}
.icon-jobs:before {
  content: "\e902";
}
.icon-news:before {
  content: "\e903";
}
.icon-products:before {
  content: "\e904";
}
.icon-profile:before {
  content: "\e905";
}
.icon-training-courses:before {
  content: "\e906";
}
